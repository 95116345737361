
import { ClassActions } from "@/store/vm/class/enums";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

interface OptionSelect {
  label: string;
  value: string | number;
}

export default defineComponent({
  name: "class-selector",
  emits: ["change"],
  props: {
    name: {
      type: String,
      default: "classId",
    },
    value: {
      type: Number,
    },
  },
  computed: {
    classIdTarget: {
      get() {
        if (this.value === 0) {
          this.$emit("change", {
            name: this.$props.name,
            value: this.defaultValue,
          });
          return this.defaultValue;
        }
        return this.value;
      },
      set(modelValue) {
        this.$emit("change", {
          name: this.$props.name,
          value: modelValue,
        });
      },
    },
  },
  setup() {
    const store = useStore();
    const defaultValue = ref(0);

    onMounted(() => {
      if (list.value.length === 0) {
        store.dispatch(ClassActions.GET_ALL_CLASS).catch((e) => {
          console.log(e);
        });
      } else {
        apply();
      }
    });
    const options = ref<OptionSelect[]>([]);
    const list = computed(() => {
      return store.state.ClassModule.list;
    });

    watch(list, (currentValue) => {
      if (currentValue.length > 0) {
        apply();
      }
    });

    const apply = () => {
      const currentValue = list.value;
      const opts: OptionSelect[] = [];
      currentValue.map((m) => {
        opts.push({
          value: m.class_id,
          label: m.name,
        });
      });
      options.value = opts;
      defaultValue.value = currentValue[0].class_id;
    };

    return {
      list,
      options,
      defaultValue,
      apply,
    };
  },
});
