<!-- Demo5Draggable.vue -->
<template>
  <div>
    <el-select
      v-model="actionType"
      class="m-2"
      placeholder="Select"
      size="large"
    >
      <el-option
        v-for="item in actionTypeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <div style="padding-left: 10px">
      <div v-show="actionType === 'url'">
        <label class="required fs-6 fw-bold mb-2">URL</label>
        <div>
          <el-form-item>
            <el-input v-model="url" type="text" placeholder="" />
          </el-form-item>
        </div>
      </div>

      <div v-show="actionType === 'news'">
        <label class="required fs-6 fw-bold mb-2">Lựa chọn tin tức</label>
        <NewsSelector
          name="newsId"
          :value="newsIdTarget"
          @change="onChange"
        ></NewsSelector>
      </div>

      <div v-show="actionType === 'course'">
        <label class="required fs-6 fw-bold mb-2">Lựa chọn khóa học</label>

        <CourseSelector
          name="courseId"
          :value="courseIdTarget"
          @change="onChange"
        ></CourseSelector>
      </div>

      <div v-show="actionType === 'class'">
        <label class="required fs-6 fw-bold mb-2">Lựa chọn lớp học</label>
        <ClassSelector
          name="classId"
          :value="classIdTarget"
          @change="onChange"
        ></ClassSelector>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import CourseSelector from "@/views/course/selector/Course.vue";
import ClassSelector from "@/views/class/selector/Class.vue";
import NewsSelector from "@/views/news/selector/News.vue";

export default {
  components: {
    CourseSelector,
    ClassSelector,
    NewsSelector,
  },
  props: {
    actionTypeProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
    actionIdProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
    urlProp: {
      type: [String, Boolean],
      required: true,
      default: false,
    },
  },
  methods: {
    get: function () {
      let actionId = 0;
      switch (this.actionType) {
        case "course":
          actionId = this.courseIdTarget;
          break;
        case "class":
          actionId = this.classIdTarget;
          break;
        case "news":
          actionId = this.newsIdTarget;
          break;
        default:
      }
      return {
        actionType: this.actionType,
        actionId,
        url: this.url,
      };
    },
  },
  setup(props) {
    const actionType = ref("");
    const courseIdTarget = ref(0);
    const classIdTarget = ref(0);
    const newsIdTarget = ref(0);
    const url = ref("");

    const actionTypeOptions = [
      {
        value: "url",
        label: "Mở đường dẫn URL",
      },
      {
        value: "news",
        label: "Mở tin tức trong hệ thống",
      },
      {
        value: "course",
        label: "Mở chi tiết khóa học",
      },
      {
        value: "class",
        label: "Mở chi tiết lớp học",
      },
    ];

    const init = () => {
      actionType.value = props.actionTypeProp || "url";

      if (props.actionTypeProp === "course") {
        courseIdTarget.value = props.actionIdProp;
      }

      if (props.actionTypeProp === "class") {
        classIdTarget.value = props.actionIdProp;
      }

      if (props.actionTypeProp === "news") {
        newsIdTarget.value = props.actionIdProp;
      }

      if (props.actionTypeProp === "url") {
        url.value = props.urlProp;
      }
    };

    watch(
      () => props.actionTypeProp,
      () => {
        init();
      }
    );

    onMounted(() => {
      init();
    });

    const onChange = (payload) => {
      if (payload.name === "courseId") {
        courseIdTarget.value = payload.value;
      }

      if (payload.name === "classId") {
        classIdTarget.value = payload.value;
      }

      if (payload.name === "newsId") {
        newsIdTarget.value = payload.value;
      }
    };

    return {
      classIdTarget,
      courseIdTarget,
      newsIdTarget,
      actionType,
      actionTypeOptions,
      onChange,
      url,
    };
  },
};
</script>
