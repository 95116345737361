
import { NewsActions } from "@/store/vm/news/enums";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

interface OptionSelect {
  label: string;
  value: string | number;
}

export default defineComponent({
  name: "news-selector",
  emits: ["change"],
  props: {
    name: {
      type: String,
      default: "newsId",
    },
    value: {
      type: Number, // Set prop value = 0 -> auto select valid default value
    },
  },
  computed: {
    newsIdTarget: {
      get() {
        if (this.value === 0) {
          this.$emit("change", {
            name: this.$props.name,
            value: this.defaultValue,
          });
          return this.defaultValue;
        }
        return this.value;
      },
      set(modelValue) {
        this.$emit("change", {
          name: this.$props.name,
          value: modelValue,
        });
      },
    },
  },
  setup() {
    const store = useStore();
    const defaultValue = ref(0);
    const loading = ref(false);

    onMounted(() => {
      store.dispatch(NewsActions.GET_NEWS_LIST).catch((e) => {
        console.log(e);
      });
    });
    const options = ref<OptionSelect[]>([]);
    const list = computed(() => {
      return store.state.NewsModule.list;
    });

    watch(list, (currentValue) => {
      if (currentValue.length > 0) {
        const opts: OptionSelect[] = [];
        currentValue.map((m) => {
          const time = new Date(m.created_at);
          opts.push({
            value: m.id,
            label: m.title + " (Tạo lúc: " + time.toLocaleString() + ")",
          });
        });
        options.value = opts;
        defaultValue.value = currentValue[0].id;
      }
    });

    const remoteMethod = async (query: string) => {
      loading.value = true;
      await store
        .dispatch(NewsActions.GET_NEWS_LIST, {
          perPage: 20,
          currentPage: 1,
          textSearch: query || undefined,
        })
        .catch((e) => {
          console.log(e);
        });
      loading.value = false;
    };

    return {
      list,
      options,
      defaultValue,
      loading,
      remoteMethod,
    };
  },
});
