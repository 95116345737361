
import { computed, defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import {
  NotificationActions,
  NotificationGetters,
} from "@/store/vm/notification/enums";
import { StepResult } from "@/store/captain/CaptainModule";
import NotificationAction from "@/views/notification/child/NotificationAction.vue";
import NotificationTarget from "@/views/notification/child/NotificationTarget.vue";
import NotificationTime from "@/views/notification/child/NotificationTime.vue";

export default defineComponent({
  name: "upsert-notification",
  components: {
    NotificationAction,
    NotificationTarget,
    NotificationTime,
  },
  props: {
    notificationId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["reset"],
  setup(props, { emit }) {
    const store = useStore();

    const target = ref();
    const time = ref();
    const action = ref();

    const formRef = ref<null | HTMLFormElement>(null);
    const notificationUpsertModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const defaultData = {
      notificationId: "",
      title: "",
      content: "",
    };

    const formData = ref({ ...defaultData });

    const modalUpsertId = computed(() => {
      return store.state.NotificationModule.modalUpsertId;
    });

    const rules = ref({
      title: [
        {
          required: true,
          message: "Yêu cầu nhập tiêu đề thông báo",
          trigger: "change",
        },
      ],
      content: [
        {
          required: true,
          message: "Yêu cầu nhập nội dung thông báo",
          trigger: "change",
        },
      ],
    });

    const isCreate = computed(() => {
      return !props.notificationId;
    });

    const notificationIdTarget = computed(() => {
      return props.notificationId;
    });

    watch(notificationIdTarget, (currentValue) => {
      if (currentValue) {
        const detail =
          store.getters[NotificationGetters.GET_BY_ID](currentValue);
        formData.value = detail;
      } else {
        reset();
      }
    });

    const reset = () => {
      formData.value = defaultData;
      emit("reset");
    };

    const actionCreate = (par) => {
      return store.dispatch(NotificationActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      return await store
        .dispatch(NotificationActions.UPDATE, par)
        .catch((e) => {
          console.log(e);
        });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;

          const targetData = target.value.get();
          const timeData = time.value.get();
          const actionData = action.value.get();

          const url = actionData["url"];
          const actionExtras = url ? JSON.stringify({ url }) : "{}";

          const params = {
            title: formData.value.title,
            content: formData.value.content,
            targetType: targetData["targetType"],
            targetId: targetData["targetId"],
            sendAt: timeData["sendAt"],
            actionType: actionData["actionType"],
            actionTargetId: actionData["actionId"],
            actionExtras,
          };

          if (props.notificationId) {
            stepResult = await actionUpdate({
              ...params,
              notificationId: props.notificationId,
            });
          } else {
            stepResult = await actionCreate({
              ...params,
            });
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            hideModal(notificationUpsertModalRef.value);
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            reset();
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    return {
      modalUpsertId,
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      notificationUpsertModalRef,
      notificationIdTarget,
      target,
      action,
      time,
    };
  },
});
