
import { NotificationActions } from "@/store/vm/notification/enums";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment";

export default defineComponent({
  name: "notification-list",
  components: {},
  props: {
    widgetClasses: String,
  },
  emits: ["upsert", "del"],
  data() {
    return {
      loadingModule: true,
    };
  },
  setup(props, { emit }) {
    const store = useStore();
    const checked = ref(false);
    const { t } = useI18n();

    onMounted(() => {
      store.dispatch(NotificationActions.GET_ALL_NOTIFICATION).catch((e) => {
        console.log(e);
      });
    });

    const list = computed(() => {
      return store.state.NotificationModule.list;
    });

    const count = computed(() => {
      return store.state.NotificationModule.list.length;
    });

    const upsertNotification = (notificationId) => {
      console.log("upsert emit");
      emit("upsert", notificationId);
    };

    const delNotification = (notificationId) => {
      emit("del", notificationId);
    };

    const modalUpsertId = store.state.NotificationModule.modalUpsertId;

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    const labelStatus = [
      {
        value: 0,
        label: "Đang chờ",
        class: "text-info",
      },
      {
        value: 1,
        label: "Đang xử lý",
        class: "text-warning",
      },
      {
        value: 2,
        label: "Đã gửi",
        class: "text-success",
      },
      {
        value: 3,
        label: "Gặp lỗi",
        class: "text-danger",
      },
    ];

    const getLabelStatus = (statusId) => {
      return labelStatus.filter((m) => m.value === parseInt(statusId))[0][
        "label"
      ];
    };

    const getClassNameStatus = (statusId) => {
      return labelStatus.filter((m) => m.value === parseInt(statusId))[0][
        "class"
      ];
    };

    return {
      list,
      checked,
      count,
      upsertNotification,
      delNotification,
      modalUpsertId,
      lang,
      moment,
      labelStatus,
      getLabelStatus,
      getClassNameStatus,
    };
  },
});
