<template>
  <div>
    <div style="margin-bottom: 8px">
      <el-checkbox v-model="isSendNow" label="Gửi ngay và luôn" size="large" />
    </div>

    <div v-if="!isSendNow">
      <div>
        <label class="required fs-6 fw-bold mb-2">Lựa chọn thời gian</label>
      </div>
      <el-date-picker
        v-model="sendAt"
        type="datetime"
        placeholder="Chọn thời điểm"
        format="YYYY/MM/DD hh:mm:ss"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  components: {},
  props: {},
  methods: {
    get: function () {
      if (this.isSendNow) {
        return {
          sendAt: "now",
        };
      }

      return {
        sendAt: Math.floor(this.sendAt.getTime() / 1000),
      };
    },
  },
  setup() {
    const isSendNow = ref(true);
    const time = new Date();
    const sendAt = ref(time.setTime(time.getTime() + 1));

    return {
      isSendNow,
      sendAt,
    };
  },
};
</script>
