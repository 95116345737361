
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/notification/List.vue";
import Upsert from "@/views/notification/Upsert.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { NotificationActions } from "@/store/vm/notification/enums";

interface ComponentData {
  notificationIdModal: number | boolean;
}
export default defineComponent({
  name: "notification",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
    Upsert,
  },
  data(): ComponentData {
    return {
      notificationIdModal: false,
    };
  },
  methods: {
    onUpsert(notificationId: number | boolean) {
      this.notificationIdModal = notificationId;
    },
    onReset() {
      this.notificationIdModal = false;
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Notification", ["Thông báo"]);
      MenuComponent.reinitialization();
    });

    const onDel = (notificationId) => {
      // const detail =
      //   store.getters[NotificationGetters.GET_BY_ID](notificationId);

      Swal.fire({
        title: "Bạn muốn xóa thông báo này?",
        text: "Mọi thông báo trong danh sách thông báo cá nhân của người dùng (nếu có) cũng sẽ được xóa.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(notificationId);
          console.log("resDel");
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (notificationId) => {
      return store
        .dispatch(NotificationActions.DELETE, notificationId)
        .catch((e) => {
          console.log(e);
        });
    };

    return {
      onDel,
    };
  },
});
