
import { CourseActions } from "@/store/vm/course/enums";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

interface OptionSelect {
  label: string;
  value: string | number;
}

export default defineComponent({
  name: "course-selector",
  emits: ["change"],
  props: {
    name: {
      type: String,
      default: "courseId",
    },
    value: {
      type: Number, // Set prop value = 0 -> auto select valid default value
    },
    courseType: {
      type: Array,
      default: () => {
        return ["online", "offline", "video"];
      },
    },
  },
  computed: {
    courseIdTarget: {
      get() {
        if (this.value === 0) {
          this.$emit("change", {
            name: this.$props.name,
            value: this.defaultValue,
          });
          return this.defaultValue;
        }
        return this.value;
      },
      set(modelValue) {
        this.$emit("change", {
          name: this.$props.name,
          value: modelValue,
        });
      },
    },
  },
  setup(props) {
    const store = useStore();
    const defaultValue = ref(0);

    onMounted(() => {
      store.dispatch(CourseActions.GET_ALL_COURSE).catch((e) => {
        console.log(e);
      });
    });

    const courseTypeAllow = computed(() => {
      return props.courseType;
    });

    const options = ref<OptionSelect[]>([]);
    const list = computed(() => {
      const listAll = store.state.CourseModule.list;
      return listAll.filter((course) =>
        courseTypeAllow.value.includes(course.type_code)
      );
    });

    watch(list, (currentValue) => {
      if (currentValue.length > 0) {
        const opts: OptionSelect[] = [];
        currentValue.map((m) => {
          opts.push({
            value: m.course_id,
            label: m.name,
          });
        });
        options.value = opts;
        defaultValue.value = currentValue[0].course_id;
      }
    });

    return {
      courseTypeAllow,
      list,
      options,
      defaultValue,
    };
  },
});
