<!-- Demo5Draggable.vue -->
<template>
  <div>
    <el-select
      v-model="targetType"
      class="m-2"
      placeholder="Select"
      size="large"
    >
      <el-option
        v-for="item in targetTypeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <div style="padding-left: 10px">
      <div v-show="targetType === 'course'">
        <label class="required fs-6 fw-bold mb-2">Lựa chọn khóa học</label>

        <CourseSelector
          name="courseId"
          :value="courseIdTarget"
          @change="onChange"
        ></CourseSelector>
      </div>

      <div v-show="targetType === 'class'">
        <label class="required fs-6 fw-bold mb-2">Lựa chọn lớp học</label>
        <ClassSelector
          name="classId"
          :value="classIdTarget"
          @change="onChange"
        ></ClassSelector>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import CourseSelector from "@/views/course/selector/Course.vue";
import ClassSelector from "@/views/class/selector/Class.vue";

export default {
  components: {
    CourseSelector,
    ClassSelector,
  },
  props: {
    targetTypeProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
    targetIdProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  methods: {
    get: function () {
      let targetId = 0;
      switch (this.targetType) {
        case "course":
          targetId = this.courseIdTarget;
          break;
        case "class":
          targetId = this.classIdTarget;
          break;
        default:
      }
      return {
        targetType: this.targetType,
        targetId,
      };
    },
  },
  setup(props) {
    const targetType = ref("");
    const courseIdTarget = ref(0);
    const classIdTarget = ref(0);

    const targetTypeOptions = [
      {
        value: "all",
        label: "Tất cả người dùng",
      },
      {
        value: "course",
        label: "Theo chương trình học",
      },
      {
        value: "class",
        label: "Theo lớp học",
      },
    ];

    const init = () => {
      targetType.value = props.targetTypeProp || "all";
      if (props.targetTypeProp === "course") {
        courseIdTarget.value = props.targetIdProp;
      }

      if (props.targetTypeProp === "class") {
        classIdTarget.value = props.targetIdProp;
      }
    };

    watch(
      () => props.targetTypeProp,
      () => {
        init();
      }
    );

    onMounted(() => {
      init();
    });

    const onChange = (payload) => {
      if (payload.name === "courseId") {
        courseIdTarget.value = payload.value;
      }

      if (payload.name === "classId") {
        classIdTarget.value = payload.value;
      }
    };

    return {
      classIdTarget,
      courseIdTarget,
      targetType,
      targetTypeOptions,
      onChange,
    };
  },
};
</script>
